import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, collection, query, getDocs, deleteDoc, doc, orderBy, limit, startAfter, where } from '../../config/firebase';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Typography } from '@mui/material';

const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [businessFilter, setBusinessFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [total, setTotal] = useState(0);
  const [businesses, setBusinesses] = useState([]);
  const [dateRangeTotal, setDateRangeTotal] = useState(0);

  const navigate = useNavigate();
  const pageSize = 10;

  const fetchExpenses = async (lastDoc = null) => {
    setLoading(true);
    try {
      let q = query(collection(db, 'expenses'));
  
      // Apply filters
      if (businessFilter) {
        q = query(q, where('business', '==', businessFilter));
      }
  
      // Apply ordering
      q = query(q, orderBy('date', 'desc'));
  
      if (startDate && endDate) {
        q = query(q, where('date', '>=', startDate), where('date', '<=', endDate));
      }
  
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }
  
      q = query(q, limit(pageSize));
  
      const expenseSnapshot = await getDocs(q);
      const expensesData = expenseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      setExpenses(prev => lastDoc ? [...prev, ...expensesData] : expensesData);
      setLastVisible(expenseSnapshot.docs[expenseSnapshot.docs.length - 1]);
      setHasMore(expenseSnapshot.docs.length === pageSize);
  
      if (businessFilter) {
        const totalAmount = expensesData.reduce((sum, expense) => sum + expense.price, 0);
        setTotal(totalAmount);
      } else {
        setTotal(0);
      }

      // Calculate total expenses for the date range
      const totalInRange = expensesData.reduce((sum, expense) => sum + expense.price, 0);
      setDateRangeTotal(totalInRange);

    } catch (error) {
      console.error("Error fetching expenses: ", error);
      if (error.code === 'failed-precondition' || error.code === 'permission-denied') {
        alert('The query requires a custom index. Please visit the Firebase console to create the index.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const fetchBusinesses = async () => {
    try {
      const q = query(collection(db, 'expenses'));
      const expenseSnapshot = await getDocs(q);
      const businessSet = new Set(expenseSnapshot.docs.map(doc => doc.data().business));
      setBusinesses([...businessSet]);
    } catch (error) {
      console.error("Error fetching businesses: ", error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [businessFilter, startDate, endDate]);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchExpenses(lastVisible);
    }
  };

  const handleEdit = (id) => {
    navigate(`/pos/edit-expense/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'expenses', id));
      setExpenses(prev => prev.filter(expense => expense.id !== id));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          label="Business"
          value={businessFilter}
          onChange={(e) => setBusinessFilter(e.target.value)}
          select
          fullWidth
          sx={{ mr: 2 }}
        >
          <MenuItem value="">All Businesses</MenuItem>
          {businesses.map(business => (
            <MenuItem key={business} value={business}>
              {business}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ mr: 2 }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        {`Total expenses from ${startDate || 'start'} to ${endDate || 'end'}: ${dateRangeTotal.toFixed(2)} AED`}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Business</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map(expense => (
              <TableRow key={expense.id}>
                <TableCell>{expense.title}</TableCell>
                <TableCell>{expense.date}</TableCell>
                <TableCell>{expense.item}</TableCell>
                <TableCell>{expense.business}</TableCell>
                <TableCell>{expense.amount}</TableCell>
                <TableCell>{expense.price}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(expense.id)} color="primary">Edit</Button>
                  <Button onClick={() => handleDelete(expense.id)} color="secondary">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
            {businessFilter && (
              <TableRow>
                <TableCell colSpan={4}><strong>Total for {businessFilter}</strong></TableCell>
                <TableCell colSpan={2}><strong>{total}</strong></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && (
        <Button onClick={handleLoadMore} disabled={loading} sx={{ mt: 2 }}>
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </Box>
  );
};

export default ExpenseReport;
