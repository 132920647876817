import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Typography, Box, FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button } from '@mui/material';
import { db, collection, query, getDocs, deleteDoc, doc, orderBy, limit, startAfter } from '../../config/firebase';
import Alert from '../Alert';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';

const SalesReport = () => {
  const [sales, setSales] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const pageSize = 10;

  const fetchSales = async (lastDoc = null) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, 'sales'),
        orderBy('date', 'desc'),
        limit(pageSize)
      );
      
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const salesSnapshot = await getDocs(q);
      const salesList = salesSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date ? data.date.toDate() : null,
          customer: data.customer || 'Cash Customer',
        };
      });
      
      setSales(prev => lastDoc ? [...prev, ...salesList] : salesList);
      setLastVisible(salesSnapshot.docs[salesSnapshot.docs.length - 1]);
      setHasMore(salesSnapshot.docs.length === pageSize);
    } catch (error) {
      console.error("Error fetching sales: ", error);
      setAlert({ type: 'error', message: 'Failed to fetch sales. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchSales();

      const productsSnapshot = await getDocs(collection(db, 'elements'));
      const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);

      const customersSnapshot = await getDocs(collection(db, 'customers'));
      const customersList = customersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers([{ id: 'cash', name: 'Cash Customer' }, ...customersList]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = sales;

    if (startDate) {
      filtered = filtered.filter(sale => sale.date && sale.date >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(sale => sale.date && sale.date <= new Date(endDate));
    }

    if (selectedCustomer) {
      filtered = filtered.filter(sale => sale.customer === selectedCustomer);
    }

    if (selectedProduct) {
      filtered = filtered.filter(sale => sale.products.some(product => product.productId === selectedProduct));
    }

    setFilteredSales(filtered);
  }, [sales, startDate, endDate, selectedCustomer, selectedProduct]);

  const handleSortChange = (event) => {
    const option = event.target.value;
    setSortOption(option);
    let sortedSales = [...filteredSales];

    switch (option) {
      case 'bestSelling':
        sortedSales.sort((a, b) => {
          const totalA = a.products.reduce((sum, prod) => sum + prod.quantity, 0);
          const totalB = b.products.reduce((sum, prod) => sum + prod.quantity, 0);
          return totalB - totalA;
        });
        break;
      case 'bestCustomer':
        sortedSales.sort((a, b) => b.totalPrice - a.totalPrice);
        break;
      default:
        sortedSales.sort((a, b) => b.date - a.date);
    }

    setFilteredSales(sortedSales);
  };

  const handleDeleteSale = async (saleId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete the sale record?");
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'sales', saleId));
      setSales(sales.filter(sale => sale.id !== saleId));
      setAlert({ type: 'success', message: 'Sale deleted successfully!' });
    } catch (error) {
      console.error('Error deleting sale: ', error);
      setAlert({ type: 'error', message: 'Failed to delete sale. Please try again.' });
    }
  };

  const handleEditSale = (saleId) => {
    navigate(`/pos/edit-sale/${saleId}`);
  };

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchSales(lastVisible);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Sales Report</Typography>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Customer</InputLabel>
          <Select
            value={selectedCustomer}
            onChange={(e) => setSelectedCustomer(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {customers.map(customer => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Product</InputLabel>
          <Select
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {products.map(product => (
              <MenuItem key={product.id} value={product.id}>
                {product.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            onChange={handleSortChange}
          >
            <MenuItem value="">Most Recent</MenuItem>
            <MenuItem value="bestSelling">Best Selling Items</MenuItem>
            <MenuItem value="bestCustomer">Best Customer</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {alert.message && (
        <Alert type={alert.type} message={alert.message} />
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Products</TableCell>
            <TableCell>Total Price</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSales.map(sale => (
            <TableRow key={sale.id}>
              <TableCell>{sale.date ? sale.date.toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{customers.find(c => c.id === sale.customer)?.name || 'Cash Customer'}</TableCell>
              <TableCell>
                {sale.products.map(product => (
                  <Typography key={product.productId}>
                    {products.find(p => p.id === product.productId)?.title || 'Unknown Product'} (x{product.quantity})
                  </Typography>
                ))}
              </TableCell>
              <TableCell>{sale.totalPrice ? `${sale.totalPrice.toFixed(2)} AED` : 'N/A'}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditSale(sale.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteSale(sale.id)}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hasMore && (
        <Button onClick={handleLoadMore} disabled={loading} sx={{ mt: 2 }}>
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </Box>
  );
};

export default SalesReport;